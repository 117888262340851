<style scoped>
 .imgcontainer {
   display: flex;
   justify-content: center;
   align-items: center;
  /* margin-top: 2vh;*/

 }
 .imgobj {
   display:block;
  /* border-radius: 20px;*/
   /*width: 98vw;*/
   object-fit: cover;
 }
</style>
<template>
  <div class="imgcontainer">
     <img :src="imageSrc24" class="imgobj" />
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc12" class="imgobj" />
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc25" class="imgobj" />
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc2" class="imgobj" />
  </div>
  <div class="imgcontainer">
    <img :src="imagesrc05" class="imgobj" />
  </div>
</template>
<script>

export default {
  data(){
    return {
      start: 0,
      items: ['hello','world'], // 这里填充你的数据
      imageSrc24: require('@/assets/01.png'),
      imageSrc12: require('@/assets/02.png'),
      imageSrc25: require('@/assets/03.png'),
      imageSrc2: require('@/assets/04.png'),
      imagesrc05:require('@/assets/05.png')
    }
  }
}
</script>

